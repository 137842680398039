@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  animation-delay: 1s;
}

.animated.delay-2s {
  animation-delay: 2s;
}

.animated.delay-3s {
  animation-delay: 3s;
}

.animated.delay-4s {
  animation-delay: 4s;
}

.animated.delay-5s {
  animation-delay: 5s;
}

.animated.fast {
  animation-duration: .8s;
}

.animated.faster {
  animation-duration: .5s;
}

.animated.slow {
  animation-duration: 2s;
}

.animated.slower {
  animation-duration: 3s;
}

@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    transition-duration: 1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }
}

.logo.is-animetion {
  margin-top: .6em;
}

.logo.is-animetion span {
  animation: 1s ease-in-out infinite wave-text;
  display: inline-block;
}

.logo.is-animetion {
  span: nth-of-type(1) { animation-delay: 0s; } span:nth-of-type(2) { animation-delay: .1s; } span:nth-of-type(3) { animation-delay: .2s; } span:nth-of-type(4) { animation-delay: .3s; } span:nth-of-type(5) { animation-delay: .4s; };
}

@keyframes wave-text {
  0% {
    transform: translateY(0);
  }

  60% {
    transform: translateY(-.6em);
  }

  100% {
    transform: translateY(0);
  }
}

/*# sourceMappingURL=create-high-quality-content-to-drive-conversions.80ff9284.css.map */
